// const API_URL = "https://codexotech.in/projects/chokhidhanifoodsuae/csadmin/api/testingapi/"
// const API_URL = "https://codexo.in/2023/chokhidhanifoodsuae/csadmin/api/"
// const API_URL='https://chokhidhanifoodsuae.studykee.com/csadmin/api/testingapi'
const API_URL= 'https://chokhidhanifoodsuae.com/csadmin/api/testingapi/'
const BASE_URL = "https://codexo.in/2023/chokhidhanifoodsuae/csadmin/"
const GOOGLE_URL = "https://maps.googleapis.com/maps/api/"
const FRONT_URL = "https://heartswithfingers.com/"
const DEFAULT_IMAGE = "/img/defaultimage.png";
const GOOGLE_MAP_KEY = "AIzaSyCJrkzyUKM6gAm585P3CNbim9rwMJPE6zU";
const CATEGORIES = [];
const themesetting = {};
export default { API_URL, CATEGORIES, themesetting, BASE_URL, FRONT_URL, DEFAULT_IMAGE,GOOGLE_URL,GOOGLE_MAP_KEY };