import React, { useEffect, useState, useRef } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { ApiService } from "../../Components/Services/apiservices";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import constant from "../../Components/Services/constant";
import CategoryWiseProducts from "../../Components/Elements/category_wise_products";
import TagWiseProducts from "../../Components/Elements/tag_wise_products";
import SpinnerLoader from "../../Components/Elements/spinner_loader";
import HomeTopBanner from "../../Components/Elements/home_top_banner";
import FeaturedCategories from "../../Components/Elements/featured_categories";
import GridBannerFirst from "../../Components/Elements/grid_banner_first";
import sessionCartData from "../../Components/Elements/cart_session_data";
import { Helmet } from "react-helmet";
import CategoryHtml from "../../Components/Elements/category_html";
import { Swiper, SwiperSlide } from "swiper/react";
function Home() {
  const [spinnerLoading, setSpinnerLoading] = useState(true);
  const didMountRef = useRef(true);
  const dataArray = sessionCartData();
  const parsedCartSession = dataArray[1];
  const [cartCount, setCartCount] = useState(parsedCartSession.length);
  const [pageData, setPageData] = useState("")
  const [mediaImageUrl, setMediaImageUrl] = useState("")
  const [sectionData, setSectionData] = useState([])
  const [mobileSectionData, setMobileSectionData] = useState([])
  useEffect(() => {
    if (didMountRef.current) {
      setTimeout(() => {
        setSpinnerLoading(false);
      }, 1000);
      getDashboardData();
      const getPageData = {
        slug: "home",
      };
      ApiService.postData("page-content", getPageData).then((res) => {
        if (res.status == "success") {
          setPageData(res.data)

        } else {

        }
      });
    }
    didMountRef.current = false;
  }, []);
  const getDashboardData = () => {
    ApiService.fetchData("dashboard").then((res) => {
      if (res.status == "success") {
        setMediaImageUrl(res.mediaImageUrl)
        setSectionData(res.resAppearanceHomeData)
        setMobileSectionData(res.resAppearanceHomeMobileData)
      }
    }).catch(()=>{});
  };

  const handleCartCount = (status) => {
    const dataArray = sessionCartData();
    const parsedCartSession = dataArray[1];
    setCartCount(parsedCartSession.length)
  };
  return (
    <>
      <Helmet>
        <title>{pageData.page_meta_title}</title>
        <meta name="description" itemprop="description" content={pageData.page_meta_desc != null ? pageData.page_meta_desc : "Chokhi Dhani Foods UAE"} />
        {pageData.page_meta_keyword != null ? <meta name="keywords" content={pageData.page_meta_keyword} /> : ""}
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={pageData.page_meta_title} />
        <meta property="og:image" content={constant.FRONT_URL + 'img/logo.png'} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content={pageData.page_meta_desc != null ? pageData.page_meta_desc : "Chokhi Dhani Foods UAE"} />
        <meta name="twitter:title" content={pageData.page_meta_title} />
        <meta name="twitter:description" content={pageData.page_meta_desc != null ? pageData.page_meta_desc : "Chokhi Dhani Foods UAE"} />
        <meta property="twitter:image" content={constant.FRONT_URL + 'img/logo.png'} />
        <meta name="twitter:url" content={window.location.href} />
      </Helmet>
      {spinnerLoading && <SpinnerLoader />}
      <BrowserView>
        <Header cartCount={cartCount} />
        <HomeTopBanner />
        {sectionData.length > 0 && (
          sectionData.map((valueSection, indexSection) => (
            <section className={valueSection.home_top_spaced + " " + valueSection.home_bottom_spaced} key={indexSection}>
              <div className="container">
                <div className={"row  " + valueSection.home_grid}>
                  {valueSection.has_many_home_inner.length > 0 &&
                    valueSection.has_many_home_inner.map((valueRowInner, indexRowInner) => (
                      <><div className={"col-lg-" + valueRowInner.home_inn_structure_type} key={indexRowInner}>
                        {valueRowInner.has_many_home_details.length > 0 &&
                          valueRowInner.has_many_home_details.map((valueRow, indexRow) => {
                            let dataObj;
                            if (valueRow.home_type === 1) {
                              if (valueRow.home_layout_for_type === 1) {
                                dataObj = {
                                  'id': valueRow.home_cat_id,
                                  'home_product_name': valueRow.home_product_name,
                                  'home_column': valueRow.home_column,
                                  'home_display_type': valueRow.home_display_type,
                                  'home_no_items': valueRow.home_no_items,
                                  'home_col_grid': 'cols-xl-',
                                };
                                return (
                                  <CategoryWiseProducts
                                    key={indexRow}
                                    onParentData={handleCartCount}
                                    dataObj={dataObj}
                                  />
                                );
                              } else {
                                dataObj = {
                                  'id': valueRow.home_tag_id,
                                  'home_product_name': valueRow.home_product_name,
                                  'home_column': valueRow.home_column,
                                  'home_display_type': valueRow.home_display_type,
                                  'home_no_items': valueRow.home_no_items,
                                  'home_col_grid': 'cols-xl-',
                                };
                                return (
                                  <TagWiseProducts
                                    key={indexRow}
                                    onParentData={handleCartCount}
                                    dataObj={dataObj}
                                  />
                                );
                              }
                            } else if (valueRow.home_type === 2) {
                              dataObj = {
                                'id': valueRow.home_tag_id,
                                'home_product_name': valueRow.home_cat_title,
                                'home_column': valueRow.home_column,
                                'home_display_type': valueRow.home_display_type,
                                'home_no_items': valueRow.home_no_items,
                                'home_col_grid': 'cols-xl-',
                                'home_cat_multi_id': valueRow.home_cat_multi_id,
                              };
                              return (
                                <CategoryHtml
                                  key={indexRow}
                                  onParentData={handleCartCount}
                                  dataObj={dataObj}
                                />
                              );
                            } else if (valueRow.home_type === 3) {
                              return (
                                <p key={indexRow}></p>
                              );
                            } else if (valueRow.home_type === 4) {
                              return (
                                <div key={indexRow}>
                                  <p>{valueRow.home_text_title}</p>
                                  <div dangerouslySetInnerHTML={{ __html: valueRow.home_text_description }}></div>
                                </div>
                              );
                            } else if (valueRow.home_type === 5) {
                              dataObj = {
                                'home_image_link': valueRow.home_image_link,
                                'home_image_url': valueRow.home_image_url,
                                'home_image': valueRow.home_image,
                                'spinnerLoading': spinnerLoading,
                              };
                              return (
                                <GridBannerFirst
                                  key={indexRow}
                                  onParentData={handleCartCount}
                                  dataObj={dataObj}
                                />
                              );
                            } else if (valueRow.home_type === 6) {
                              dataObj = {
                                'home_image_link': valueRow.home_image_link,
                                'home_image_url': valueRow.home_image_url,
                                'home_image': valueRow.home_image,
                                'spinnerLoading': spinnerLoading,
                              };
                              return (
                                <div className="swipergallery">
                                <Swiper
                                  loop={true}
                                  spaceBetween={valueRow.home_space_between_slide}
                                  navigation={{
                                    nextEl: ".swiper-button-next",
                                    prevEl: ".swiper-button-prev",
                                  }}
                                  breakpoints={{
                                    0: {
                                      slidesPerView: valueRow.home_column,
                                    },
                                    600: {
                                      slidesPerView: valueRow.home_column,
                                    },
                                    1000: {
                                      slidesPerView: valueRow.home_column,
                                    },
                                  }}>
                                  {valueRow.has_many_gallery && valueRow.has_many_gallery.length > 0 && valueRow.has_many_gallery.map((galleryValue, indexgallery) => {
                                      return (
                                          <SwiperSlide key={indexgallery}>
                                              <div key={indexgallery}>
                                                  <img src={galleryValue.home_gallery_image_url != null ? mediaImageUrl + galleryValue.home_gallery_image_url : constant.DEFAULT_IMAGE} alt='gallery' title='gallery'></img>
                                              </div>
                                          </SwiperSlide>
                                      );
                                  })}
                                </Swiper>
                                </div>
                              );
                            }
                          })}
                      </div></>
                    ))}
                </div>
              </div>
            </section>
          ))
        )}
        <Footer />
      </BrowserView>
      <MobileView>
        <Header cartCount={cartCount} />
        <FeaturedCategories />
        <HomeTopBanner />
        {mobileSectionData.length > 0 && (
          mobileSectionData.map((valueSection, indexSection) => (
            <section className={valueSection.home_top_spaced + " " + valueSection.home_bottom_spaced} key={indexSection}>
              <div className="container">
                <div className={"row  " + valueSection.home_grid}>
                {valueSection.has_many_home_inner.length > 0 &&
                    valueSection.has_many_home_inner.map((valueRowInner, indexRowInner) => (
                      <><div className={"col-" + valueRowInner.home_inn_structure_type} key={indexRowInner}>
                  {valueRowInner.has_many_home_details.length > 0 &&
                    valueRowInner.has_many_home_details.map((valueRow, indexRow) => {
                      let dataObj;
                      if (valueRow.home_type === 1) {
                        if (valueRow.home_layout_for_type === 1) {
                          dataObj = {
                            'id': valueRow.home_cat_id,
                            'home_product_name': valueRow.home_product_name,
                            'home_column': valueRow.home_column,
                            'home_display_type': valueRow.home_display_type,
                            'home_no_items': valueRow.home_no_items,
                            'home_col_grid': 'cols-',
                          };
                          return (
                            
                              <CategoryWiseProducts
                                key={indexRow}
                                onParentData={handleCartCount}
                                dataObj={dataObj}
                              />
                          );
                        } else {
                          dataObj = {
                            'id': valueRow.home_tag_id,
                            'home_product_name': valueRow.home_product_name,
                            'home_column': valueRow.home_column,
                            'home_display_type': valueRow.home_display_type,
                            'home_no_items': valueRow.home_no_items,
                            'home_col_grid': 'cols-',
                          };
                          return (
                              <TagWiseProducts
                                key={indexRow}
                                onParentData={handleCartCount}
                                dataObj={dataObj}
                              />
                          );
                        }
                      } else if (valueRow.home_type === 2) {
                        dataObj = {
                          'id': valueRow.home_tag_id,
                          'home_product_name': valueRow.home_cat_title,
                          'home_column': valueRow.home_column,
                          'home_display_type': valueRow.home_display_type,
                          'home_no_items': valueRow.home_no_items,
                          'home_col_grid': 'cols-',
                          'home_cat_multi_id': valueRow.home_cat_multi_id,
                        };
                        return (
                            <CategoryHtml
                              key={indexRow}
                              onParentData={handleCartCount}
                              dataObj={dataObj}
                            />
                        );
                      } else if (valueRow.home_type === 3) {
                        return (
                          <div className={"col-lg-" + valueRow.home_layout_type} key={indexRow}>
                            <p key={indexRow}></p>
                          </div>
                        );
                      } else if (valueRow.home_type === 4) {
                        return (
                          <div key={indexRow}>
                            <p>{valueRow.home_text_title}</p>
                            <div dangerouslySetInnerHTML={{ __html: valueRow.home_text_description }}></div>
                          </div>
                        );
                      } else if (valueRow.home_type === 5) {
                        dataObj = {
                          'home_image_link': valueRow.home_image_link,
                          'home_image_url': valueRow.home_image_url,
                          'home_image': valueRow.home_image,
                          'spinnerLoading': spinnerLoading,
                        };
                        return (
                            <GridBannerFirst
                              key={indexRow}
                              onParentData={handleCartCount}
                              dataObj={dataObj}
                            />
                        );
                      } else if (valueRow.home_type === 6) {
                        dataObj = {
                          'home_image_link': valueRow.home_image_link,
                          'home_image_url': valueRow.home_image_url,
                          'home_image': valueRow.home_image,
                          'spinnerLoading': spinnerLoading,
                        };
                        return (
                          <div className="swipergallery">
                          <Swiper
                            loop={true}
                            spaceBetween={valueRow.home_space_between_slide}
                            navigation={{
                              nextEl: ".swiper-button-next",
                              prevEl: ".swiper-button-prev",
                            }}
                            breakpoints={{
                              0: {
                                slidesPerView: valueRow.home_column,
                              },
                              600: {
                                slidesPerView: valueRow.home_column,
                              },
                              1000: {
                                slidesPerView: valueRow.home_column,
                              },
                            }}>
                            {valueRow.has_many_gallery && valueRow.has_many_gallery.length > 0 && valueRow.has_many_gallery.map((galleryValue, indexgallery) => {
                                return (
                                    <SwiperSlide key={indexgallery}>
                                        <div key={indexgallery}>
                                            <img src={galleryValue.home_gallery_image_url != null ? mediaImageUrl + galleryValue.home_gallery_image_url : constant.DEFAULT_IMAGE} alt='gallery' title='gallery'></img>
                                        </div>
                                    </SwiperSlide>
                                );
                            })}
                          </Swiper>
                          </div>
                        );
                      }
                    })}
                    </div></>
                ))}
                </div>
              </div>
            </section>
          ))
        )}
        <Footer />
      </MobileView>
    </>
  );
}
export default Home;
